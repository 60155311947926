// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
// import "./src/normalize.css"
// custom CSS styles
// import "./src/style.css"
import "./node_modules/bootstrap/dist/css/bootstrap.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.hash) {
    return false
  }

  return true
}
